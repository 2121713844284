import React, {FC} from 'react';
import {Route, Switch} from 'react-router-dom';
import SignIn from './SignIn/SignIn';
import {useTranslation} from 'react-i18next';
import './style.scss';
import {useSelector} from 'react-redux';
import SignUp from './SignUp/SignUp';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import SentPassword from './SentPassword/SentPassword';
import NewPassword from './NewPassword/NewPassword';
import NotFound from './NotFound/NotFound';
import Config from '../Providers/Config';

const Login: FC = () => {
  const {t} = useTranslation();
  const bgImg = useSelector(state => state.login.bgImg);

  return (
    <div id="login-component">
      <div className="head">
        <div className="logo">
          <img
            src={`/assets/images/${Config.Common.logo}`}
            alt="logo"
            height={50}
          />
        </div>
        <a
          href="tel:+380674448820"
          className="call"
        >
          <img
            src={'/assets/images/call-answer.svg'}
            alt="call"
            height={25}
          />
        </a>
      </div>
      <div className="bg">
        <div
          className="img"
          style={{backgroundImage: `url(${bgImg})`}}
        />
        {
          ['my.gr8logistics.com'].indexOf(window.location.host) !== -1 ?
            <div className="reward">
              <h4>{t('login:reward.title')}</h4>
              <p>
                {t('login:reward.text')}
              </p>
            </div>
            :
            ''
        }
      </div>
      <div id="logo">
        <img
          src={`/assets/images/${Config.Common.logoWhite}`}
          alt="logo"
          height={50}
        />
      </div>
      <div className="content">
        <Switch>
          <Route
            component={SignIn}
            path="/"
            exact={true}
          />
          <Route
            component={SignUp}
            path="/sign-up"
          />
          <Route
            component={ForgotPassword}
            path="/forgot-password"
          />
          <Route
            component={SentPassword}
            path="/sent-password/:email?"
          />
          <Route
            component={NewPassword}
            path="/new-password/:code"
          />
          <Route
            component={NotFound}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Login;
